<template>
  <v-text-field v-model="computedColor" :rules="rules" :disabled="!edit" outlined :label="label" :mask="mask">
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="110" nudge-left="20" :close-on-content-click="closeOnClick">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on">
            <v-tooltip v-if="contrastTextColor" top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" :style="contrastTextStyle" v-on="on">Aa</span>
              </template>
              <span>Cor de contraste</span>
            </v-tooltip>
          </div>
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="computedColor" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    label: String,
    color: {
      type: String,
      default: ''
    },
    contrastTextColor: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    edit: Boolean
  },
  data: () => ({
    mask: '!#XXXXXXXX',
    menu: false,
    computedColor: '',
    closeOnClick: false
  }),
  computed: {
    swatchStyle() {
      const { color, menu } = this
      this.$emit('update:color', this.computedColor)

      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        margin: '0 3px 14px',
        padding: '20px',
        borderRadius: menu ? '50%' : '3px',
        borderStyle: 'inset',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    contrastTextStyle() {
      return {
        color: this.contrastTextColor,
        fontSize: '24px'
      }
    }
  },
  watch: {
    color() {
      if (!!this.color && !!this.computedColor && this.color !== this.computedColor) {
        this.computeColor(this.color)
      }
    }
  },
  mounted() {
    this.computeColor(this.color)
  },
  methods: {
    computeColor(colorFromProps) {
      this.computedColor = colorFromProps
    }
  }
}
</script>

<style></style>
